// material
import { Box } from '@mui/material';
import CONFIGURATION from '../app_configuration';

// ----------------------------------------------------------------------

const { CLIENT } = CONFIGURATION;
const CLIENT_KEY = CLIENT?.toLowerCase() || 'default';

export default function Logo({ sx, symbolOnly = false }) {
	const symbolLogoPath = `/images/logos/${CLIENT_KEY}/logo.png`;
	return symbolOnly ? (
		<Box
			onClick={() => window.open('/', '_self')}
			sx={{ width: 70, position: 'relative', height: 135, margin: 'auto', ...sx }}
		>
			<img alt="logo" src={symbolLogoPath} onError={e => handleImageError(e, 'symbol')} />
		</Box>
	) : (
		<Box
			onClick={() => window.open('/', '_self')}
			sx={{ width: 145, position: 'relative', height: 35, ...sx }}
		>
			<img
				alt="logo"
				src={`/images/logos/${CLIENT_KEY}/logo-full.png`}
				onError={e => handleImageError(e, 'full')}
			/>
		</Box>
	);
}

// Handle image errors with a fallback path when client non existant
function handleImageError(event, logoType) {
	event.target.src =
		logoType === 'symbol'
			? '/images/logos/default/logo.png'
			: '/images/logos/default/logo-full.png';
}
