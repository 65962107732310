import * as Yup from 'yup';
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
// material
import {
	Link,
	Stack,
	Alert,
	Checkbox,
	TextField,
	IconButton,
	InputAdornment,
	FormControlLabel,
	Box,
	Typography,
	Button
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import ConfirmCodeForm from '../../SignUp/ConfirmCodeForm';
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
import config from '../../../app_configuration';

// ----------------------------------------------------------------------

export default function LoginForm() {
	const { login, resendConfirmationCode } = useAuth();
	const navigate = useNavigate();
	const isMountedRef = useIsMountedRef();
	const [showPassword, setShowPassword] = useState(false);
	const [confirmCode, setConfirmCode] = useState(false);
	const [isLoggingIn, setIsLoggingIn] = useState(false);

	const LoginSchema = Yup.object().shape({
		email: Yup.string()
			.email('Email must be a valid email address')
			.required('Email is required'),
		password: Yup.string().required('Password is required')
	});

	const handleAuthError = (error, credentials) => {
		if (error.code) {
			switch (error.code) {
				case 'UserNotConfirmedException':
					resendConfirmationCode(credentials.email);
					setConfirmCode({ ...credentials });
					break;
				case 'PasswordResetRequiredException':
					navigate('/auth/reset-password');
					break;
				default:
					console.log('auth error', error.code);
			}
		}
	};

	const formik = useFormik({
		initialValues: {
			email: '',
			password: '',
			remember: true
		},
		validationSchema: LoginSchema,
		onSubmit: async (values, { setErrors, resetForm }) => {
			setIsLoggingIn(true);
			try {
				const response = await login(values.email, values.password);
				if (response.newPasswordRequired) {
					setIsLoggingIn(false);
				}
			} catch (error) {
				setIsLoggingIn(false);
				handleAuthError(error, values);
				resetForm();
				if (isMountedRef.current) {
					setErrors({ afterSubmit: error.message });
				}
			}
		}
	});

	const { errors, touched, values, handleSubmit, getFieldProps } = formik;

	const handleShowPassword = () => {
		setShowPassword(show => !show);
	};

	const handleLoginRedirect = () => {
		login();
	};

	return config.VENDOR === 'AWS' ? (
		confirmCode ? (
			<>
				<Typography variant="h4">Verify your email</Typography>
				<Typography variant="body1">
					Verification code has been sent to your email
				</Typography>
				<br />
				<ConfirmCodeForm credentials={{ ...confirmCode }} />
			</>
		) : (
			<FormikProvider value={formik}>
				<Form autoComplete="off" noValidate onSubmit={handleSubmit}>
					<Stack spacing={3}>
						<Box sx={{ flexGrow: 1 }}>
							<Typography textAlign="center" variant="h4" gutterBottom>
								Sign In
							</Typography>
							<Typography textAlign="center" sx={{ color: 'text.secondary' }}>
								Enter your details and press button below to sign in
							</Typography>
						</Box>
						{errors.afterSubmit && <Alert severity="error">{errors.afterSubmit}</Alert>}
						<TextField
							InputLabelProps={{ shrink: true }}
							fullWidth
							autoComplete="username"
							type="email"
							label="Email address"
							{...getFieldProps('email')}
							error={Boolean(touched.email && errors.email)}
							helperText={touched.email && errors.email}
						/>
						<TextField
							fullWidth
							InputLabelProps={{ shrink: true }}
							autoComplete="current-password"
							type={showPassword ? 'text' : 'password'}
							label="Password"
							{...getFieldProps('password')}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<IconButton onClick={handleShowPassword} edge="end">
											<Icon
												icon={
													showPassword ? 'solar:eye-bold' : 'mdi:eye-off'
												}
											/>
										</IconButton>
									</InputAdornment>
								)
							}}
							error={Boolean(touched.password && errors.password)}
							helperText={touched.password && errors.password}
						/>
					</Stack>
					<Stack
						direction="row"
						alignItems="center"
						justifyContent="space-between"
						sx={{ my: 2 }}
					>
						<FormControlLabel
							control={
								<Checkbox
									{...getFieldProps('remember')}
									checked={values.remember}
								/>
							}
							label="Remember me"
						/>

						<Link component={RouterLink} variant="subtitle2" to="/auth/reset-password">
							Forgot password
						</Link>
					</Stack>

					<LoadingButton
						fullWidth
						size="large"
						type="submit"
						variant="contained"
						loading={isLoggingIn}
					>
						Sign in
					</LoadingButton>
				</Form>
				<Typography color="text.secondary" variant="body2" align="center" sx={{ mt: 3 }}>
					Don’t have an account?&nbsp;
					<Link variant="subtitle2" component={RouterLink} to="/auth/register">
						Get started
					</Link>
				</Typography>
			</FormikProvider>
		)
	) : (
		<>
			<Stack direction="row" alignItems="center" sx={{ mb: 5, textAlign: 'center' }}>
				<Box sx={{ flexGrow: 1 }}>
					<Typography variant="h4" gutterBottom>
						Sign In
					</Typography>
					<Typography sx={{ color: 'text.secondary' }}>
						Press button below to sign in
					</Typography>
				</Box>
			</Stack>
			<Button
				fullWidth
				size="large"
				type="submit"
				variant="contained"
				onClick={handleLoginRedirect}
			>
				Sign in
			</Button>
		</>
	);
}
