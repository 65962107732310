const CONFIGURATION = {
	AWS_REGION: process.env.REACT_APP_AWS_REGION,
	AWS_COGNITO_IDENTITY_POOL_ID: process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
	AWS_COGNITO_USER_POOL_ID: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
	AWS_COGNITO_CLIENT_ID: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID,
	AWS_API_SERVICE: process.env.REACT_APP_AWS_API_SERVICE ?? 'gateway',
	AWS_API_NAME: process.env.REACT_APP_AWS_API_NAME ?? 'nola-api',
	AWS_CHATAPI_NAME: process.env.REACT_APP_AWS_CHATAPI_NAME ?? 'nola-chatapi',
	AWS_API_URL: ''.concat(process.env.REACT_APP_AWS_API_URL, '/api/v1'),
	AWS_CHATAPI_URL: ''.concat(process.env.REACT_APP_NOLA_BOT_URL, '/chat_api/v1'),
	AWS_WHISPER_API_NAME: process.env.REACT_APP_AWS_WHISPER_API_NAME ?? 'nola-whisper-api',
	AWS_WHISPER_API_URL: process.env.REACT_APP_AWS_WHISPER_API_URL,
	ENVIRONMENT: process.env.REACT_APP_ENVIRONMENT ?? 'dev',
	RESPONSUM_API: process.env.REACT_APP_RESPONSUM_API ?? 'nola',
	VENDOR: process.env.REACT_APP_VENDOR ?? 'AWS',
	AZURE_CLIENT_ID: process.env.REACT_APP_AZURE_CLIENT_ID,
	AZURE_AUTH_ID: process.env.REACT_APP_AZURE_AUTH_ID,
	COGNITO_DOMAIN: process.env.REACT_APP_AWS_COGNITO_DOMAIN,
	CLIENT: process.env.REACT_APP_CLIENT ?? 'default'
};
export default CONFIGURATION;
