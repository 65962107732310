/* eslint-disable import/no-cycle */
import { createRoot } from 'react-dom/client'; // Import createRoot
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './components/App';
import store from './reduxStore';
import { useAuthProvider } from './hooks/useAuthProvider';

const AuthProvider = useAuthProvider();

const location = window.location.href;
const isLocalhost = location.includes('localhost');

function noop() {}
if (!isLocalhost) {
	console.log = noop;
	console.warn = noop;
	console.error = noop;
}

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
	<Provider store={store}>
		<BrowserRouter>
			<AuthProvider>
				<App />
			</AuthProvider>
		</BrowserRouter>
	</Provider>
);
